<template>
  <div class="nowhere">
    <div class="upload">
      <material-button v-if="state === 'ToBeLoaded'" text="Carica csv" type="info" style="width: 150px;" @click="onUploadStarted()" />
      <material-button v-else-if="state === 'Loaded'" text="Rimuovi csv" type="danger" style="width: 150px;" @click="cancelUpload()" />
      <material-button v-else text="Chiudi csv" type="success" style="width: 150px;" @click="cancelUpload()" />

      <!-- only csv files -->
      <input id="actual-btn" type="file" hidden accept=".csv" @change="onUploadEnded($event)">
      <span>{{ fileName || 'Nessun file caricato' }}</span>

      <material-button v-if="state === 'Loaded'" text="Salva" type="success" style="width: 150px; margin-left: auto;" @click="saveCSV()" />
    </div>

    <div class="table-div">
      <table v-if="state !== 'ToBeLoaded'" cellspacing="0">
        <thead>
          <tr>
            <th>
              N°
            </th>
            <th>
              LINK
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr v-for="(row, i) in rows" :key="i">
            <td>
              {{ i + 1 }}
            </td>
            <td>
              {{ row[0] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from 'vue'
import { sbapibackoffice } from '@/api'
import { spinnerStore } from '@/stores/spinner'

const spinner = spinnerStore()
const toast = inject('$toast')

const fileName = ref('')

const rows = ref([])

const state = ref('ToBeLoaded') // ToBeLoaded | Loaded | Saved

const onUploadStarted = () => {
  document.getElementById('actual-btn').click()
}

const cancelUpload = () => {
  fileName.value = ''
  state.value = 'ToBeLoaded'
  document.getElementById('actual-btn').value = ''
}

const onUploadEnded = ({ target }) => {
  const file = target.files[0]
  if (!file) return

  // check file extension. need to be .csv
  if (!file.name.endsWith('.csv')) {
    toast.error('Il file deve essere un csv')
    return
  }

  // the file name must contain ISO 8601: YYYY-MM-DD in it. and it must be the same as the date of the day
  const date = new Date()
  const dateStr = date.toISOString().split('T')[0]
  if (!target.files[0].name.includes(dateStr)) {
    toast.error('Il nome del file deve contenere la data di oggi')
    return
  }

  fileName.value = file.name
  state.value = 'Loaded'
  const reader = new FileReader()
  reader.readAsText(file, 'UTF-8')

  reader.onload = (event) => {
    rows.value = []

    var csvdata = event.target.result
    csvdata = csvdata.replace(/\r/g, '')
    var csvRows = csvdata.split('\n')

    for (var r = 1; r < csvRows.length; r++) {
      const rowColData = csvRows[r].split(',')
      rows.value.push(rowColData)
    }
  }
}

const saveCSV = () => {
  spinner.show()

  const obj = {
    Link: rows.value.map((row) => row[0]).join(','),
    Motivo: rows.value.map(() => ('undef')).join(',')
  }

  sbapibackoffice
    .post('/nowhere/campaigns/unpublished-indeed-feed', obj)
    .then(() => {
      toast.success('CSV caricato con successo')
      state.value = 'Saved'
    })
    .catch((err) => toast.error('Qualcosa è andato storto, ', err))
    .finally(() => {
      spinner.hide()
    })
}
</script>

<style lang="scss" scoped>
@use '../assets/scss/referrals';
@use '../assets/scss/table';

.nowhere {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .upload {
    display: flex;
    gap: 1rem;
    align-items: center;
    color: white;
  }

  .table-div {
    height: calc(100vh - 160px);
    overflow-y: auto;

    table {
      color: white;
      border-collapse: collapse;
      border: 1px solid rgba(244, 244, 244, 0.2196078431);
      width: 100%;

      thead {
        background-color: #1a74a3; //#3f51b5;
        position: sticky;
        top: -1px;
      }

      th {
        padding: 5px;
        text-align: left;
      }

      td {
        padding: 5px;
        border: 1px solid rgba(244, 244, 244, 0.2196078431);
      }
    }
  }
}
</style>
